import { Capacitor } from "@capacitor/core";
import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { locationService } from "@src/app/openShifts/urgentShifts/locationService";
import { type CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { type CbhFeatureFlags } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { GeoLocation } from "@src/appV2/Location/index";
import { getUserCurrentLocation } from "@src/appV2/Location/providers/hypertrackLocationProvider";
import { type Shift, ShiftStages } from "@src/lib/interface/src/lib/shift";
import { RadarTrackCallback } from "capacitor-radar";
import HyperTrack, { OrderStatus } from "hypertrack-sdk-ionic-capacitor";
import { pick } from "lodash";

type Coordinates = [number, number] | undefined;

interface GeofenceStatusParams {
  shift: Shift;
  isHyperTrackEnabledForClockInOut: boolean;
  hyperTrackGeotagEvents: CbhFeatureFlags[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS];
  checkFacilityGeofence: () => Promise<{
    isInsideGeofence: boolean;
    isSuccess: boolean;
    radarLocation: RadarTrackCallback | undefined;
  }>;
  stage?: ShiftStages;
}

interface GeofenceStatusResult {
  isLocationExperienceEnabled: boolean;
  isOutsideFacilityGeofence: boolean;
  location: Coordinates;
}

export async function getGeofenceStatus({
  shift,
  isHyperTrackEnabledForClockInOut,
  hyperTrackGeotagEvents,
  checkFacilityGeofence,
  stage,
}: GeofenceStatusParams): Promise<GeofenceStatusResult> {
  let isLocationExperienceEnabled = false;
  let isOutsideFacilityGeofence = false;
  let location: Coordinates;

  if (shift.facility?.featureSettings?.radarTimekeepingValidations && shift.geofence) {
    if (isHyperTrackEnabledForClockInOut) {
      const orderHandle = `${shift._id}-${shift.agentId}`;

      const { isInsideGeofence, error } = await checkIsWorkerInsideFacilityGeofenceHyperTrack({
        orderHandle,
        stage,
        hyperTrackGeotagEvents,
      });

      if (error) {
        isLocationExperienceEnabled = false;
      } else {
        isOutsideFacilityGeofence = !isInsideGeofence;
        const { longitude, latitude } = await getUserCurrentLocation();
        location = longitude && latitude ? [longitude, latitude] : undefined;
        isLocationExperienceEnabled = true;

        logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK, {
          metadata: {
            isHyperTrackEnabledForClockInOut,
            shift: pick(shift, ["shiftId", "agentId", "facilityId"]),
          },
        });
      }
    } else {
      const { isInsideGeofence, isSuccess, radarLocation } = await checkFacilityGeofence();
      isLocationExperienceEnabled = isSuccess;
      isOutsideFacilityGeofence = isSuccess && !isInsideGeofence;
      const { longitude, latitude } = radarLocation?.location || {};
      location = longitude && latitude ? [longitude, latitude] : undefined;
    }
  }

  return { isLocationExperienceEnabled, isOutsideFacilityGeofence, location };
}

export async function getDeviceLocationWithProvider(params: {
  isHyperTrackEnabledForClockInOut?: boolean;
}): Promise<{ location?: GeoLocation; error?: string }> {
  const { isHyperTrackEnabledForClockInOut } = params;

  try {
    if (isHyperTrackEnabledForClockInOut) {
      const { longitude, latitude } = await getUserCurrentLocation();

      if (isNullOrUndefined(longitude) || isNullOrUndefined(latitude)) {
        return { error: "Error getting location from Hypertrack" };
      }

      return {
        location: {
          longitude,
          latitude,
        },
      };
    }

    const { location } = await locationService.getLocation({
      desiredAccuracy: "high",
    });

    if (isNullOrUndefined(location)) {
      return { error: "Error getting location from Radar" };
    }

    return { location };
  } catch (error) {
    return {
      error: `Unhandled error getting location from ${
        isHyperTrackEnabledForClockInOut ? "Hypertrack" : "Radar"
      }`,
    };
  }
}

async function checkIsWorkerInsideFacilityGeofenceHyperTrack({
  orderHandle,
  stage,
  hyperTrackGeotagEvents,
}: {
  orderHandle: string;
  stage?: ShiftStages;
  hyperTrackGeotagEvents: CbhFeatureFlags[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS];
}): Promise<{ isInsideGeofence: boolean; error?: unknown }> {
  const { events } = hyperTrackGeotagEvents;
  const shouldGeotagFailures = events === "failures" || events === "all";
  const shouldGeotagSuccesses = events === "all";

  if (!Capacitor.isNativePlatform()) {
    const nonNativePlatformError = new Error(
      `Failed to fetch current order due to non-native platform.`
    );

    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: nonNativePlatformError,
        metadata: {
          orderHandle,
        },
      },
      true
    );

    return { isInsideGeofence: false, error: nonNativePlatformError };
  }

  const activeOrders = await HyperTrack.getOrders();
  const currentOrder = activeOrders.get(orderHandle);
  let orderStatus: OrderStatus = { type: "orderStatusClockIn" };
  if (stage === ShiftStages.CLOCK_OUT) {
    orderStatus = { type: "orderStatusClockOut" };
  }

  const now = new Date().toISOString();

  if (isNullOrUndefined(currentOrder)) {
    const activeOrderHandles = [...activeOrders.keys()];

    const failedToFetchCurrentOrderError = new Error(`Current order not found`);
    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: failedToFetchCurrentOrderError,
        metadata: {
          orderHandle,
          activeOrderHandles,
          numActiveOrders: activeOrderHandles.length,
        },
      },
      true
    );

    if (shouldGeotagFailures) {
      await HyperTrack.addGeotag(orderHandle, orderStatus, {
        activeOrders,
        numActiveOrders: activeOrderHandles.length,
        reason: failedToFetchCurrentOrderError.message,
        isInsideGeofence: false,
        attemptedAt: now,
        stage,
      });
    }

    return { isInsideGeofence: false, error: failedToFetchCurrentOrderError };
  }

  switch (currentOrder.isInsideGeofence.type) {
    case "success": {
      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED, {
        metadata: {
          orderHandle,
          activeOrders,
          currentOrder,
        },
      });

      if (currentOrder.isInsideGeofence.value) {
        if (shouldGeotagSuccesses) {
          await HyperTrack.addGeotag(orderHandle, orderStatus, {
            isInsideGeofence: true,
            attemptedAt: now,
            stage,
          });
        }
      } else {
        if (shouldGeotagFailures) {
          await HyperTrack.addGeotag(orderHandle, orderStatus, {
            reason: "Too far away",
            isInsideGeofence: false,
            attemptedAt: now,
            stage,
          });
        }
      }

      return { isInsideGeofence: currentOrder.isInsideGeofence.value };
    }
    case "failure": {
      const failedToFetchGeofenceStatusError = new Error(
        `Failed to fetch geofence status for current order`
      );
      logError(
        APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
        {
          error: failedToFetchGeofenceStatusError,
          metadata: {
            orderHandle,
            activeOrders,
            currentOrder,
          },
        },
        true
      );

      if (shouldGeotagFailures) {
        await HyperTrack.addGeotag(orderHandle, orderStatus, {
          activeOrders,
          reason: failedToFetchGeofenceStatusError.message,
          isInsideGeofence: false,
          attemptedAt: now,
          stage,
        });
      }

      return { isInsideGeofence: false, error: failedToFetchGeofenceStatusError };
    }
  }
}
