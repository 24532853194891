export const SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION = {
  toastName: "fcf_success_toast",
  header: "Your request has been sent",
  message:
    "The shift will remain in your calendar until the workplace confirms your sent home request.",
  duration: 5000,
};

export const SENT_HOME_REQUEST_FAILURE_MESSAGE_OPTION = {
  toastName: "fcf_failure_toast",
  header: "Oops, your request didn't go through",
  message: "Please try again or contact the workplace directly.",
  duration: 5000,
};

export const DEFAULT_CONFIRMATION_ALERT_OPTION = {
  header: "Are you sure?",
  message:
    "You are currently not at the workplace. Falsely reporting the workplace sent you home may negatively impact the status of your account. Please confirm you were sent home by the workplace.",
};

export const SHIFT_CANCEL_DEFAULT_FAILURE_MESSAGE =
  "There was an error while canceling your shift, please try again or contact Clipboard Health.";

enum ShiftSelfCancelErrorCode {
  FACILITY_ALREADY_CANCELED = "FACILITY_ALREADY_CANCELED",
  SENT_HOME = "SENT_HOME",
  SHIFT_NOT_FOUND = "SHIFT_NOT_FOUND",
  SHIFT_REMOVED = "SHIFT_REMOVED",
}

export const ShiftSelfCancelErrors: Record<ShiftSelfCancelErrorCode, string> = {
  [ShiftSelfCancelErrorCode.FACILITY_ALREADY_CANCELED]:
    "This shift has already been canceled by the workplace.",
  [ShiftSelfCancelErrorCode.SENT_HOME]:
    "You can no longer cancel this shift. You've already submitted a sent-home request. View details at the My Shifts page.",
  [ShiftSelfCancelErrorCode.SHIFT_NOT_FOUND]: SHIFT_CANCEL_DEFAULT_FAILURE_MESSAGE,
  [ShiftSelfCancelErrorCode.SHIFT_REMOVED]: SHIFT_CANCEL_DEFAULT_FAILURE_MESSAGE,
};

export const HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION = 4;

export const USE_MOCKED_NFC_FOR_DEVELOPMENT = false;

export enum DIGITAL_TIMESHEET_SIGNATURE_STEPS {
  HCP_SIGNATURE = "HCP_SIGNATURE",
  HCF_BREAK_CONFIRMATION = "HCF_BREAK_CONFIRMATION",
  HCF_NAME = "HCF_NAME",
  HCF_INFO = "HCF_INFO",
  HCF_SIGNATURE = "HCF_SIGNATURE",
}

export enum TIMESHEET_TYPE {
  PHOTO = "photo",
  CDPH530 = "CDPH-530",
}

export enum SIGNED_TYPE {
  HCP = "HCP",
  HCF = "HCF",
}

export enum SHIFT_ACTION_CHECK_TYPES {
  LOCATION = "LOCATION",
  NFC = "NFC",
}

export const MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES = 6 * 60;
export const MANDATORY_BREAK_DURATION_IN_MINUTES = 30;
export const MANDATORY_BREAK_DURATION_ALERT_THRESHOLD_IN_MINUTES = 25;
// Keep in sync with MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS at backend-main/src/modules/shifts/constants.ts
export const MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS = 6;

export const EARLY_CLOCK_IN_ENABLE_LIMIT_IN_MINUTES = 10;
// Break is considered early if shift completion percentage is less than this value
export const EARLY_BREAK_ALERT_THRESHOLD_PERCENTAGE = 0.2;
export const EARLY_CLOCK_OUT_ALERT_THRESHOLD_PERCENTAGE = 0.8;

export const MAX_ATTENDANCE_SCORE = 100;
